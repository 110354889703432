import React from 'react';
import Loadable from 'react-loadable';
import { LoadingPage } from '@/components/loading/loadingBase';
import PrivateProvider from '@/components/providers/PrivateProvider';
import HeaderMetadata from '@/layouts/HeaderMetadata';

const OnBoardingLoadable = Loadable({
  loader: () => import('@/sections/OnBoarding/draw-report'),
  loading: (props) => <LoadingPage isLoading={true} error={props.error} />,
});

const OnBoardingPage: React.FC = () => {
  return (
    <React.Fragment>
      <HeaderMetadata title="Mailing Create" />
      <PrivateProvider>
        <OnBoardingLoadable />
      </PrivateProvider>
    </React.Fragment>
  );
};

export default OnBoardingPage;
